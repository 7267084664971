import { gql } from '@apollo/client';

const SupplierAdminQuery = gql`
  query {
    supplierAdmin {
      id
      emailAddress
      fullName
      cognitoId
      cognitoLastAuthTime
      cognitoUserPoolId
      suppliers {
        id
        allowSmsMessaging
        allowWhatsappMessaging
        allowEmailMessaging
        slug
        location {
          addressLine1
          addressLine2
          city
          postCode
        }
        activitiesLocation
        name
        phoneNumber
        email
        website
        facebook
        instagram
        twitter
        keySupplierContactName
        aboutUs
        ageMonthsStart
        ageMonthsEnd
        hubspotAccountId
        cloudinaryImageId
        additionalImageIds
        logoImageId
        additionalInformationCaptureEnabled
        reviewsEnabled
        hasCollections
        paymentAccount {
          paymentAccountId
          stripeAccountAuthorised
          stripeAccountTransfersEnabled
          stripeAccountPayoutsEnabled
        }
        links {
          name
          url
          isDeleted
        }
        categories {
          id
          name
        }
        pebbleUse
        ponchoPayStatus
        ponchoPayFirstSetupAt
        ponchoPaySetupEmailSentAt
      }
      memberships {
        id
        supplier {
          id
          name
        }
        hasRegistersAccess
      }
    }
  }
`;

export default SupplierAdminQuery;
