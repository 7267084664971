import { FC, ReactNode, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Button } from '@mantine/core';
import classNames from 'classnames';
import { NewBadge } from 'components/NewBadge/NewBadge';
import classes from './Sidebar.module.scss';

interface ISidebarLinkProps {
  href: string;
  icon: ReactNode;
  label: string;
  onClick(): void;
  disabled?: boolean;
  isNew?: boolean;
}

export const SidebarLink: FC<ISidebarLinkProps> = ({
  href,
  icon,
  label,
  onClick,
  disabled,
  isNew,
}) => {
  const [active, setActive] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (href === '/customers' && router.route === '/customer-profile/[email]') {
      setActive(true);
    } else {
      setActive(router.pathname === href);
    }
  }, [router, href]);

  return (
    <Link href={`${href}?supplierId=${router.query.supplierId}`} passHref legacyBehavior>
      <Button
        onClick={onClick}
        fullWidth
        classNames={{
          root: classNames(classes.link, {
            [classes.activeLink]: active,
            [classes.disabledLink]: disabled,
          }),
          inner: classes.linkInner,
          label: classes.linkLabel,
        }}
        component="a"
        leftSection={icon}
        size="md"
        variant="subtle"
        disabled={disabled}
        rightSection={isNew && !active ? <NewBadge /> : null}
      >
        {label}
      </Button>
    </Link>
  );
};
