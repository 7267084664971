import { useState, useEffect, useMemo, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
  ActionIcon,
  Affix,
  Button,
  Container,
  Flex,
  Group,
  Pagination,
  Text,
  Transition,
  useMantineTheme,
} from '@mantine/core';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { getScheduledActivities } from './graphql/query';
import { CardsView } from './components/CardsView';
import { TableView } from './components/TableView';
import {
  buildFiltersQueryVariables,
  getScheduledActivitiesFilters,
  ScheduledActivitiesFilters,
} from './ScheduledActivities.utils';
import { useRouter } from 'next/router';
import { Actions, trackAction } from 'utils/amplitude';
import { useMutation } from '@apollo/client';
import { publishActivityMutation } from './graphql/mutation';
import { usePagination } from 'hooks/usePagination';
import { CloneActivityQuery } from 'graphql/queries';
import { ClonedActivityContext } from 'utils/clonedActivityContext/clonedActivityContext';
import { ClonedActivityContextType } from 'types';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';
import { ClearAllButton } from 'components/ClearAllButton';
import { FilterSelect } from 'components/FilterSelect';
import { FiltersDrawer } from 'components/FiltersDrawer';
import classes from './ScheduledActivities.module.scss';
import classNames from 'classnames';
import InviteAttendee from './components/InviteAttendee/InviteAttendee';
import { Plus, SlidersHorizontal } from '@phosphor-icons/react';
import { ActivityStatusEnum, ClassesDateFilterEnum, TermDateFilterEnum } from 'enums';
import { ActivityType } from 'interfaces';
import ActivityScheduleSummaryDrawer from 'components/ActivityScheduleSummary/ActivityScheduleSummaryDrawer';

interface IScheduledActivities {
  supplierId: string;
  token: string;
  loading: boolean;
  stripeLinked?: boolean;
}

export const ScheduledActivities: React.FC<IScheduledActivities> = (props) => {
  const router = useRouter();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const itemsPerPage = 25;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activityToDelete, setActivityToDelete] = useState<string | null>(null);
  const [classesDateFilter, setClassesDateFilter] = useState<ClassesDateFilterEnum>(
    ClassesDateFilterEnum.ALL,
  );

  // Filters
  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState(false);
  const [termDateFilter, setTermDateFilter] = useState<TermDateFilterEnum | null>(
    TermDateFilterEnum.UPCOMING,
  );

  const [selectedActivityFilter, setSelectedActivityFilter] = useState<string>('All activities');

  const [selectedVenueFilter, setSelectedVenueFilter] = useState<string>('All locations');

  const {
    data,
    refetch,
    loading: scheduledActivitiesLoading,
  } = useQuery(getScheduledActivities, {
    notifyOnNetworkStatusChange: true,
    skip: !props.supplierId || !props.token,
    fetchPolicy: 'network-only',
    variables: {
      limit: itemsPerPage,
      offset: 0,
      supplierId: props.supplierId,
      ...buildFiltersQueryVariables(termDateFilter, selectedActivityFilter, selectedVenueFilter),
    },
    context: {
      headers: {
        Authorization: `${props.token}`,
      },
    },
    onError: (error) => {
      showErrorMessage(error);
    },
  });

  const filters: ScheduledActivitiesFilters | undefined = useMemo(() => {
    return data?.activitiesBySupplier?.filters
      ? getScheduledActivitiesFilters({ ...data?.activitiesBySupplier?.filters })
      : undefined;
  }, [data]);

  const activityFilters = useMemo(() => {
    const allFilters = [
      { label: 'All activities', value: 'All activities' },
      ...(filters?.activities || []),
    ];

    const sortedFilters = allFilters.sort((a, b) => {
      // Re-order to put selected activity filter at the top of list
      if (a.value === selectedActivityFilter) return -1;
      if (b.value === selectedActivityFilter) return 1;

      // Followed by All activities
      if (a.value === 'All activities') return -1;
      if (b.value === 'All activities') return 1;

      return a.label.localeCompare(b.label);
    });

    return sortedFilters;
  }, [filters?.activities, selectedActivityFilter]);

  const venueFilters = useMemo(
    () => [{ label: 'All locations', value: 'All locations' }, ...(filters?.venues || [])],
    [filters?.venues],
  );

  const updateDateFilter = (newFilter: TermDateFilterEnum | null) => {
    setTermDateFilter(newFilter);
  };

  const resetFilters = () => {
    setSelectedActivityFilter('All activities');
    setSelectedVenueFilter('All locations');
    setTermDateFilter(TermDateFilterEnum.UPCOMING);
    setFiltersDrawerOpen(false);
  };

  // Pagination
  const { activePage, setActivePage, offset, totalPagesCount, getItemAriaLabel } = usePagination(
    data?.activitiesBySupplier?.total || 0,
    itemsPerPage,
  );
  const showControls = totalPagesCount > 0;

  useEffect(() => {
    setActivePage(1);
  }, [termDateFilter, selectedActivityFilter, selectedVenueFilter, setActivePage]);

  useEffect(() => {
    if (!refetch || !props.supplierId) {
      return;
    }

    refetch({
      limit: itemsPerPage,
      offset,
      supplierId: props.supplierId,
      ...buildFiltersQueryVariables(termDateFilter, selectedActivityFilter, selectedVenueFilter),
    });
  }, [
    refetch,
    termDateFilter,
    props.supplierId,
    offset,
    selectedActivityFilter,
    selectedVenueFilter,
  ]);

  //Publish Activity
  const [publishActivity] = useMutation(publishActivityMutation, {
    onError: (error) => {
      showErrorMessage(error);
    },
  });

  const handleStatusChange = (activityStatus: ActivityStatusEnum, activityId: string) => {
    publishActivity({
      variables: {
        input: { activityId: activityId, desiredStatus: activityStatus },
      },
      context: {
        headers: {
          Authorization: `${props.token}`,
        },
      },
    });
  };

  // Clone activity
  const { setClonedActivity } = useContext(ClonedActivityContext) as ClonedActivityContextType;

  const [getData, { data: clonedActivity, loading }] = useLazyQuery(CloneActivityQuery, {
    fetchPolicy: 'network-only',
    context: {
      headers: {
        Authorization: `${props.token}`,
      },
    },
    onCompleted: () => {
      setClonedActivity(clonedActivity?.activitiesBySupplier?.activities[0]);
      router.push(`/add-activity?supplierId=${router.query.supplierId}`);
    },
    onError: (error) => {
      showErrorMessage(error);
    },
  });

  const handleCloneActivity = (clonedActivityId: string) => {
    if (clonedActivityId) {
      getData({
        variables: {
          supplierId: props.supplierId,
          activityId: clonedActivityId,
        },
      });
    }
  };

  // Invite Attendees
  const [openInviteAttendeeContent, setOpenInviteAttendeeContent] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState<string>('');
  const handleInviteAttendee = (activityId: string) => {
    setOpenInviteAttendeeContent(true);
    setSelectedActivityId(activityId);
  };

  // ActivityScheduleSummary
  const [openedActivityScheduleSummary, { open: openActivityScheduleSummary, close }] =
    useDisclosure(false);
  const [selectedActivity, setSelectedActivity] = useState<ActivityType | null>(null);

  const handleActivitySelection = (activity: ActivityType) => {
    openActivityScheduleSummary();
    setSelectedActivity(activity);
  };

  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;

      // Hide CTA when scrolling down, show CTA when scrolling up
      if (currentScrollPosition > scrollPosition) {
        setShowButton(false);
      } else if (currentScrollPosition < scrollPosition) {
        setShowButton(true);
      }

      setScrollPosition(currentScrollPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  const actionButton = (smallSize?: boolean) => {
    const isDisabled = !props.stripeLinked;
    return (
      <Button
        size={smallSize ? 'xs' : 'md'}
        component="a"
        href={`/add-activity?supplierId=${props.supplierId}`}
        radius="lg"
        color="pink"
        leftSection={
          <Plus size={20} color={isDisabled ? theme.colors.gray[4] : 'white'} weight="bold" />
        }
        onClick={() => {
          trackAction(Actions.SUPPLIER_ADD_ACTIVITY);
        }}
        disabled={isDisabled}
        w={isMobile ? '100%' : 'max-content'}
        classNames={{
          root: classes.actionButton,
          inner: classNames(classes.actionButtonInner, {
            [classes.actionButtonInnerSmall]: smallSize,
          }),
        }}
      >
        Add an activity
      </Button>
    );
  };
  return (
    <>
      <PageHeader
        title="Scheduled activities"
        intro={
          <div data-testid="page-description">
            View, add and edit your activities here, including availability and session details.
          </div>
        }
        rightComponent={
          <Flex justify={'flex-end'} w={'100%'} mb={20}>
            {actionButton()}
          </Flex>
        }
      />
      {!loading && (
        <Group
          className={classes.toolbar}
          gap={isMobile ? '0.25rem' : 'md'}
          pt={isMobile ? 'xs' : '40px'}
          pb={'sm'}
        >
          <Group gap={isMobile ? '0.25rem' : 'xs'}>
            {!isMobile && <Text>Show: </Text>}
            <Button
              className={classNames(classes.toolbarButton, {
                [classes.active]: termDateFilter === null,
              })}
              variant="subtle"
              onClick={() => {
                updateDateFilter(null);
                trackAction(Actions.SCHEDULED_ALL);
              }}
            >
              All
            </Button>
            <Button
              className={classNames(classes.toolbarButton, {
                [classes.active]: termDateFilter === TermDateFilterEnum.UPCOMING,
              })}
              variant="subtle"
              onClick={() => {
                updateDateFilter(TermDateFilterEnum.UPCOMING);
                trackAction(Actions.SCHEDULED_UPCOMING);
              }}
            >
              Upcoming
            </Button>
            <Button
              className={classNames(classes.toolbarButton, {
                [classes.active]: termDateFilter === TermDateFilterEnum.PAST,
              })}
              variant="subtle"
              onClick={() => {
                updateDateFilter(TermDateFilterEnum.PAST);
                trackAction(Actions.SCHEDULED_PAST);
              }}
            >
              Past
            </Button>
          </Group>
          <Group>
            {!isMobile ? (
              <>
                <FilterSelect
                  defaultValue={'All activities'}
                  value={selectedActivityFilter}
                  onChange={(value) => {
                    if (!value) return;
                    setSelectedActivityFilter(value);
                    trackAction(Actions.SCHEDULED_ACTIVITIES_FILTER);
                  }}
                  // eslint-disable-next-line
                  // @ts-ignore
                  data={activityFilters}
                />
                <FilterSelect
                  defaultValue={'All locations'}
                  value={selectedVenueFilter}
                  onChange={(value) => {
                    if (!value) return;
                    setSelectedVenueFilter(value);
                    trackAction(Actions.SCHEDULED_VENUES_FILTER);
                  }}
                  // eslint-disable-next-line
                  // @ts-ignore
                  data={venueFilters}
                />
                <ClearAllButton
                  onClick={() => {
                    resetFilters();
                    trackAction(Actions.SCHEDULED_CLEAR_FILTERS);
                  }}
                />
              </>
            ) : (
              <>
                <ActionIcon
                  onClick={() => {
                    setFiltersDrawerOpen(!filtersDrawerOpen);
                  }}
                  aria-label="Open Filter Menu"
                  variant="filled"
                  color="blue.5"
                  className={classes.settingsButton}
                >
                  <SlidersHorizontal size={28} color="white" />
                </ActionIcon>
              </>
            )}
          </Group>
        </Group>
      )}

      {!isMobile ? (
        <Container fluid px="lg" mx={0} style={{ backgroundColor: '#fff' }}>
          <TableView
            token={props.token}
            loading={loading || props.loading || scheduledActivitiesLoading}
            activitiesBySupplier={data?.activitiesBySupplier?.activities}
            onDelete={() => refetch()}
            onClone={handleCloneActivity}
            onStatusChange={handleStatusChange}
            onInviteAttendee={handleInviteAttendee}
            handleActivitySelection={handleActivitySelection}
            setActivityToDelete={setActivityToDelete}
            activityToDelete={activityToDelete}
            setShowDeleteModal={setShowDeleteModal}
            showDeleteModal={showDeleteModal}
            actionButton={actionButton(true)}
          />
        </Container>
      ) : (
        <Container fluid p="lg" mx={0}>
          <CardsView
            token={props.token}
            loading={loading || props.loading || scheduledActivitiesLoading}
            activitiesBySupplier={data?.activitiesBySupplier?.activities}
            onDelete={() => refetch()}
            onClone={handleCloneActivity}
            onStatusChange={handleStatusChange}
            onInviteAttendee={handleInviteAttendee}
            handleActivitySelection={handleActivitySelection}
            setActivityToDelete={setActivityToDelete}
            activityToDelete={activityToDelete}
            setShowDeleteModal={setShowDeleteModal}
            showDeleteModal={showDeleteModal}
          />
        </Container>
      )}

      {showControls && (
        <Group justify="center" className={classes.paginationWrapper}>
          <Pagination
            withControls={showControls}
            total={totalPagesCount}
            value={activePage}
            onChange={setActivePage}
            boundaries={1}
            radius="xl"
            classNames={{
              control: classes.paginationButtons,
            }}
            getControlProps={(page) => ({
              ['aria-label']: getItemAriaLabel(page),
            })}
          />
        </Group>
      )}

      <FiltersDrawer
        open={filtersDrawerOpen}
        onClose={() => setFiltersDrawerOpen(false)}
        activityFilters={activityFilters}
        venueFilters={venueFilters}
        onActivityChange={setSelectedActivityFilter}
        onVenueChange={setSelectedVenueFilter}
        selectedActivity={selectedActivityFilter}
        selectedVenue={selectedVenueFilter}
        onClearFilters={resetFilters}
        scheduledActivities={true}
        loading={loading}
      />
      <InviteAttendee
        openInviteAttendeeContent={openInviteAttendeeContent}
        setOpenInviteAttendeeContent={setOpenInviteAttendeeContent}
        token={props.token}
        activityId={selectedActivityId}
        supplierId={props.supplierId}
      />
      <ActivityScheduleSummaryDrawer
        openedActivityScheduleSummary={openedActivityScheduleSummary}
        close={() => {
          close();
          setSelectedActivity(null);
          // setClassesDateFilter(ClassesDateFilterEnum.UPCOMING);
        }}
        selectedActivityId={selectedActivity?.id}
        token={props.token}
        supplierId={props.supplierId}
        onInviteAttendee={handleInviteAttendee}
        setActivityToDelete={setActivityToDelete}
        activityToDelete={activityToDelete}
        setShowDeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
        onDelete={() => refetch()}
        setClassesDateFilter={setClassesDateFilter}
        classesDateFilter={classesDateFilter}
      />
      {isMobile && (
        <Transition mounted={showButton} transition="fade-up" duration={250} keepMounted>
          {(styles) => (
            <Affix
              position={{ bottom: 0, left: 0, right: 0 }}
              style={{ ...styles, background: 'white', padding: 12, zIndex: 1 }}
            >
              <Flex justify="center">{actionButton()}</Flex>
            </Affix>
          )}
        </Transition>
      )}
    </>
  );
};
