import { Card, Flex, Title, Divider, useMantineTheme } from '@mantine/core';
import classes from './Card.module.scss';
import { Settings } from '@icons';
import { ComponentTypes } from '../../Home';
import { CalendarStar, CreditCard, UsersThree, ClipboardText } from '@phosphor-icons/react';
import classNames from 'classnames';

interface CardsProps {
  children: React.ReactNode;
  section: ComponentTypes;
  fullHeight?: boolean;
}

const headers = {
  setup: 'Get Set-up',
  upcoming: 'Upcoming',
  capacity: 'Class Capacity',
  finance: 'Finance',
  performance: 'Performance',
};

const Cards: React.FC<CardsProps> = ({ children, section, fullHeight = false }) => {
  const theme = useMantineTheme();

  const getIcon = () => {
    switch (section) {
      case ComponentTypes.SETUP:
        return <ClipboardText weight="duotone" size="28px" />;
      case ComponentTypes.UPCOMING:
        return <CalendarStar weight="duotone" size="28px" />;
      case ComponentTypes.CAPACITY:
        return <UsersThree weight="duotone" size="28px" />;
      case ComponentTypes.FINANCE:
        return <CreditCard weight="duotone" size="28px" />;
      default:
        return (
          <Settings
            className={classes.icon}
            color={theme.colors.blue[2]}
            strokeColour={theme.colors.blue[8]}
          />
        );
    }
  };

  return (
    <Card
      className={classNames(classes.cardWrapper, { [classes.cardWrapperFullHeight]: fullHeight })}
    >
      <Flex align={'center'}>
        {getIcon()}
        <Title order={3} pl={10} size="22px">
          {headers[section]}
        </Title>
      </Flex>
      <Divider my="sm" color={theme.colors.gray[4]} />
      {children}
    </Card>
  );
};

export default Cards;
