import { gql } from '@apollo/client';

const MessageStatus = gql`
  query MessageStatus($supplierId: UUID!, $identifiers: [String]!) {
    messageStatus(supplierId: $supplierId, identifiers: $identifiers) {
      idsAndStatuses {
        identifier
        status
      }
    }
  }
`;

export default MessageStatus;
