import { gql } from '@apollo/client';

const CustomersQuery = gql`
  query CustomersQuery($supplierId: UUID!, $emails: [String], $sessionId: UUID) {
    customers(supplierId: $supplierId, emails: $emails, sessionId: $sessionId) {
      id
      fullName
      email
      phoneNumber
      supplierMarketingOptin
      uniqueAttendeeNames
    }
  }
`;

export default CustomersQuery;
