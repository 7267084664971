import { FC, useState } from 'react';
import { SelectProps } from '@mantine/core';
import classes from './FilterSelect.module.scss';
import PebbleSelect from 'components/common/PebbleSelect/PebbleSelect';
import classNames from 'classnames';
import { CaretDown } from '@phosphor-icons/react';

interface FilterSelectProps
  extends Pick<
    SelectProps,
    'placeholder' | 'data' | 'defaultValue' | 'value' | 'onChange' | 'onFocus'
  > {
  financeDashboardStyling?: boolean;
}

export const FilterSelect: FC<FilterSelectProps> = (props) => {
  const { placeholder, defaultValue, value, onChange, onFocus, data, financeDashboardStyling } =
    props;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <PebbleSelect
      withCheckIcon={false}
      placeholder={placeholder}
      defaultValue={defaultValue}
      data={data}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onDropdownOpen={() => {
        setDropdownOpen(true);
      }}
      onDropdownClose={() => setDropdownOpen(false)}
      rightSection={
        <CaretDown
          size={18}
          weight="bold"
          className={classNames({
            [classes.iconOpen]: dropdownOpen,
            [classes.iconClose]: !dropdownOpen,
          })}
        />
      }
      classNames={{
        root: classNames({ [classes.wrapper]: financeDashboardStyling }),
        section: classes.rightSection,
        dropdown: classes.dropdown,
        option: classes.option,
        input: classNames(classes.input, {
          [classes.filterSelectedInput]: value !== defaultValue,
          [classes.financeDashboardInput]: financeDashboardStyling,
        }),
      }}
    />
  );
};
