import { gql } from '@apollo/client';

const PartialSupplierAdminQuery = gql`
  query {
    supplierAdmin {
      id
      suppliers {
        id
        slug
        name
        paymentAccount {
          stripeAccountAuthorised
        }
      }
    }
  }
`;

export default PartialSupplierAdminQuery;
