import { gql } from '@apollo/client';

const CreateMarketplaceSubscriptionRefund = gql`
  mutation createMarketplaceSubscriptionRefund(
    $input: CreateMarketplaceSubscriptionRefundInputType!
  ) {
    createMarketplaceSubscriptionRefund(input: $input) {
      refund {
        id
        status
      }
    }
  }
`;

export default CreateMarketplaceSubscriptionRefund;
