import { gql } from '@apollo/client';

const cancelSubscriptionBooking = gql`
  mutation cancelSubscriptionBooking(
    $subscriptionId: String!
    $cancellationReason: BookingCancellationEnum!
    $cancellationDetails: String
    $cancelAtPeriodEnd: Boolean!
  ) {
    cancelSubscriptionBooking(
      subscriptionId: $subscriptionId
      cancellationReason: $cancellationReason
      cancellationDetails: $cancellationDetails
      cancelAtPeriodEnd: $cancelAtPeriodEnd
    ) {
      subscriptionRequest {
        id
        status
      }
    }
  }
`;

export default cancelSubscriptionBooking;
