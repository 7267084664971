import { gql } from '@apollo/client';

const CreateMessageAttendees = gql`
  mutation createMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      output {
        recipientsAndIds {
          recipient
          identifier
        }
        messageType
      }
    }
  }
`;

export default CreateMessageAttendees;
