import { gql } from '@apollo/client';

const CreateCustomerEmail = gql`
  mutation createCustomerEmail(
    $emails: [String]!
    $emailBody: String!
    $emailSubject: String!
    $replyTo: String!
    $supplierId: UUID!
  ) {
    createCustomerEmail(
      emails: $emails
      emailBody: $emailBody
      emailSubject: $emailSubject
      replyTo: $replyTo
      supplierId: $supplierId
    ) {
      customerEmail {
        sent
      }
    }
  }
`;

export default CreateCustomerEmail;
