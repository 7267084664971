import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { CustomerFilters, CustomerDirectoryContextType } from 'types';
import { CustomerBookingStatus, CustomersSortByEnum } from 'enums';
import { useRouter } from 'next/router';

export const CustomerDirectoryContext = createContext<CustomerDirectoryContextType>({
  filters: {
    activities: [],
    bookingStatus: CustomerBookingStatus.ANY,
  },
  setFilters: () => {},
  sortByKey: CustomersSortByEnum.LATEST_BOOKING_DESC,
  setSortByKey: () => {},
  searchText: '',
  setSearchText: () => {},
  showAttendeeNames: false,
  setShowAttendeeNames: () => {},
});

interface CustomerDirectoryContextProviderProps {
  children: ReactNode;
}

export const CustomerDirectoryContextProvider: FC<CustomerDirectoryContextProviderProps> = (
  props,
) => {
  const router = useRouter();
  const [filters, setFilters] = useState<CustomerFilters>({
    activities: [],
    bookingStatus: CustomerBookingStatus.ANY,
  });
  const [searchText, setSearchText] = useState<string>('');
  const [sortByKey, setSortByKey] = useState<CustomersSortByEnum>(
    CustomersSortByEnum.LATEST_BOOKING_DESC,
  );
  const [showAttendeeNames, setShowAttendeeNames] = useState<boolean>(false);

  const isDefault = useMemo<boolean>(() => {
    const isDefaultFilters =
      filters.activities.length === 0 && filters.bookingStatus === CustomerBookingStatus.ANY;
    const isDefaultSort = sortByKey === CustomersSortByEnum.LATEST_BOOKING_DESC;
    const isDefaultSearch = searchText === '';
    const isDefaultAttendeeNames = showAttendeeNames === false;
    return isDefaultFilters && isDefaultSort && isDefaultSearch && isDefaultAttendeeNames;
  }, [filters, sortByKey, searchText, showAttendeeNames]);

  useEffect(() => {
    if (!isDefault && !router.pathname.startsWith('/customer')) {
      setFilters({
        activities: [],
        bookingStatus: CustomerBookingStatus.ANY,
      });
      setSortByKey(CustomersSortByEnum.LATEST_BOOKING_DESC);
      setSearchText('');
      setShowAttendeeNames(false);
    }
  }, [router, isDefault]);

  return (
    <CustomerDirectoryContext.Provider
      value={{
        filters,
        setFilters,
        sortByKey,
        setSortByKey,
        searchText,
        setSearchText,
        showAttendeeNames,
        setShowAttendeeNames,
      }}
    >
      {props.children}
    </CustomerDirectoryContext.Provider>
  );
};
