import { useQuery } from '@apollo/client';
import { Center, Flex, Grid, Loader, Text, useMantineTheme } from '@mantine/core';
import SupplierOnboarding from 'graphql/queries/supplier-onboarding';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import classes from './Setup.module.scss';
import { Actions, trackAction } from 'utils/amplitude';
import Link from 'next/link';
import { Briefcase, CheckSquare } from '@phosphor-icons/react';

interface SetupProps {
  token: string;
  supplierId: string;
  setSetupComplete: Dispatch<SetStateAction<boolean | undefined>>;
}

interface SupplierOnboardingData {
  supplierOnboarding: {
    complete: boolean;
    tasks: {
      complete: boolean;
      name: string;
    }[];
  };
}

interface SetupTasksType {
  [key: string]: {
    text: string;
    link: string;
  };
}

const Setup: React.FC<SetupProps> = ({ token, supplierId, setSetupComplete }) => {
  const theme = useMantineTheme();
  const { loading, data, refetch } = useQuery<SupplierOnboardingData | null>(SupplierOnboarding, {
    context: {
      headers: {
        Authorization: `${token}`,
      },
    },
    variables: {
      supplierId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch({ supplierId: supplierId });
  }, [supplierId, refetch]);

  useEffect(() => {
    setSetupComplete(data?.supplierOnboarding?.complete);
  }, [data, setSetupComplete]);

  const setupTasks: SetupTasksType = {
    addPaymentDetails: {
      text: 'Add your payment details',
      link: `/settings?supplierId=${supplierId}&activeSettingsTab=payment-details`,
    },
    addBusinessDetails: {
      text: 'Add your business details',
      link: `/settings?supplierId=${supplierId}&activeSettingsTab=business-details`,
    },
    createSupplierPage: {
      text: 'Create your provider page',
      link: `/settings?supplierId=${supplierId}&activeSettingsTab=supplier-details`,
    },
    addActivity: {
      text: 'Add an activity',
      link: `/add-activity?supplierId=${supplierId}`,
    },
  };

  const handleEvents = (name: string) => {
    switch (setupTasks[name].text) {
      case setupTasks.addPaymentDetails.text: {
        return trackAction(Actions.DASH_PAYMENT_DETAILS);
      }
      case setupTasks.addBusinessDetails.text: {
        return trackAction(Actions.DASH_BUSINESS_DETAILS);
      }
      case setupTasks.createSupplierPage.text: {
        return trackAction(Actions.DASH_SUPPLIER_DETAILS);
      }
      default: {
        return trackAction(Actions.DASH_ADD_ACTIVITY);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <Grid columns={12} mt="sm">
          {data?.supplierOnboarding?.tasks.map(
            ({ name, complete: taskComplete }: { name: string; complete: boolean }) => {
              return (
                <Grid.Col span={{ base: 12, sm: 6 }} key={name}>
                  {taskComplete ? (
                    <Flex gap="xs">
                      <CheckSquare size="20px" weight="fill" color={theme.colors.lime[8]} />
                      <Text size="16px" fw={600}>
                        {setupTasks[name].text}
                      </Text>
                    </Flex>
                  ) : (
                    <Flex gap="xs">
                      <Briefcase weight="fill" size="20px" color={theme.colors.blue[6]} />
                      <Link
                        className={classes.incompleteLink}
                        href={setupTasks[name].link}
                        onClick={() => handleEvents(name)}
                      >
                        {setupTasks[name].text}
                      </Link>
                    </Flex>
                  )}
                </Grid.Col>
              );
            },
          )}
        </Grid>
      )}
    </>
  );
};

export default Setup;
